import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import { article } from '../styles/layout.css';
import { a, h2, p } from '../styles/global.css';
import { atoms } from '../styles/sprinkles.css';

const WikiPage = () => {

  return (
    <Layout pageTitle="Wiki">
      <article className={article}>
        <h2 className={h2}>Read my thoughts</h2>
        <p className={p}><a href='https://scrapbox.io/benfoden' className={a}>My wiki</a> is updated randomly. It contains a range of ideas about my work, interests, observations, and recommendations.</p>
      </article>
    </Layout>
  );
}
export default WikiPage;
